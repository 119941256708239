export const Menus = [
  {
    icon: "mdi-tag-multiple",
    text: "Productos",
    to: "/admin/productos",
  },
  {
    icon: "mdi-factory",
    text: "Marcas",
    to: "/admin/marcas",
  },
  
  {
    icon: "mdi-account-group",
    text: "Clientes",
    to: "/admin/clientes",
  },
  
  {
    icon: "mdi-truck-check",
    text: "Proveedores",
    to: "/admin/proveedores",
  },
  
  // {
  //   icon: "mdi-currency-usd",
  //   text: "Cobros",
  //   to: "/admin/cobros",
  // },
  
  {
    icon: "mdi-finance",
    text: "Finanzas",
    categories: [
      {
        icon: "mdi-currency-usd",
        text: "Cobros",
        to: "/admin/cobros",
      },
      {
        icon: "mdi-file-powerpoint",
        text: "Planes",
        to: "/admin/planes",
      },
      {
        icon: "mdi-format-list-numbered-rtl",
        text: "Opciones",
        to: {
          name: "opciones",
        },
      },
    ],
  },
];
