<template>
  <v-navigation-drawer
    v-model="drawer"
    :mini-variant.sync="mini"
    permanent
    id="core-navigation-drawer"
    dark
    :right="$vuetify.rtl"
    mobile-break-point="960"
    app
    width="260"
    v-bind="$attrs"
  >
    <!-- vuetify avatar e img -->
    <v-list-item class="px-2">
      <!-- ICONO BTN MENU PARA ABRIR Y CERRAR -->
      <v-btn icon @click.stop="mini = !mini">
        <v-icon :class="{ menuGiratorio: mini }" large>mdi-menu-open</v-icon>
      </v-btn>
      <!-- FIN  ICONO BTN MENU -->
      <div class="pa-3">
        <v-img src="/images/nodrizablanco.png" max-width="160px" contain>
        </v-img>
      </div>
    </v-list-item>

    <template v-slot:img="props">
      <v-img :gradient="`to bottom, ${barColor}`" v-bind="props" />
    </template>

    <v-divider class="mb-1" />

    <v-list dense nav class="ma-0 pa-0">
      <v-list-item two-line>
        <v-list-item-avatar class="align-self-center">
          <img
            v-if="$store.state.tipoUser === 'proveedor'"
            :src="$store.state.user.logo"
          />
          <img v-else :src="$store.state.user.avatar" />
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>{{ $store.state.user.nombre }}</v-list-item-title>
          <v-list-item-subtitle v-if="$store.state.tipoUser === 'cliente'">{{
            $store.state.user.plan.nombre
          }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>

      <v-list v-if="$store.state.tipoUser === 'admin'" nav class="ma-0 pa-0">
        <v-list-item-group v-for="item in Menus" :key="item.text" v-model="model">
        <v-tooltip bottom>
            
            <template v-slot:activator="{ on, attrs }">
              <!-- REFACTORING MENUS -->
            
      

              <v-list-group
                v-if="item.categories"
                :prepend-icon="item.icon"
                no-action
                v-bind="attrs"
                v-on="on"
              >
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.text"></v-list-item-title>
                  </v-list-item-content>
                </template>
                <v-list-item
                  v-for="subItem in item.categories"
                  :key="subItem.title"
                  :to="subItem.to"                  
                  :class="isCurrentRoute(subItem.to) && ['active','primary--text']"
                >

                  <v-list-item-icon>
                    <v-icon v-text="subItem.icon"></v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title v-text="subItem.text"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-group>


                <v-list-item 
                  :class="isCurrentRoute(item.to) && ['active','primary--text']"
                  v-else
                  :to="item.to"
                  v-bind="attrs"
                  v-on="on"
                >

                  <v-list-item-icon>
                    <v-icon v-text="item.icon"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.text"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

            </template>
              <span v-text="item.text" />
              </v-tooltip>
              </v-list-item-group>
          <!-- FIN REFACTORING MENUS -->
          
        
      </v-list>


    <v-list nav v-if="$store.state.tipoUser === 'proveedor'">
      <v-list-group
        v-for="item in MenusProveedor"
        :key="item.text"
        :prepend-icon="item.icon"
        no-action
        :active-class="activeClass"
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item-content>
        </template>
        <v-list-item link
          v-for="subItem in PerfilProveedor"
          :key="subItem.title"
          :to="subItem.to"
        >
          <v-list-item-content>
            <v-list-item-title v-text="subItem.text"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
    </v-list>
    <v-divider class="mb-2" />

    <v-list expand nav v-if="$store.state.tipoUser == 'cliente'">
      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />
      <template v-for="(item, i) in computedItems">
        <base-item-group v-if="item.children" :key="`group-${i}`" :item="item">
          <!--  -->
        </base-item-group>

        <base-item v-else :key="`item-${i}`" :item="item" />
      </template>

      <div />
    </v-list>

    <template v-slot:append>
      <v-hover v-slot:default="{ hover }" open-delay="200">
      <v-btn 
      block
      dark 
      v-on="on"
      :elevation="hover ? 0 : 12"
      :color="hover ? 'greyDark':'black' " 
      class="btn body-2 font-weight-light"
      @click="logout()"
      >
        <v-icon class="body-1 mr-2">mdi-exit-to-app</v-icon>
        Cerrar sesión
      </v-btn>
      </v-hover>
    </template>
  </v-navigation-drawer>
</template>

<script>
// Utilities
import { mapState, mapActions } from "vuex";
import { Configuration } from "@/configuration";
import { EventBus } from "@/main.js";
import { Menus } from "@/models/admin_link.js";
import { MenusProveedor, PerfilProveedor } from "@/models/proveedor_link.js";
import Swal from "sweetalert2";
export default {
  name: "DashboardCoreDrawer",

  props: {
    expandOnHover: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    model:1,
    mini: true,
    drawer: true,
    Menus,
    PerfilProveedor,
    MenusProveedor,
    items: [
      {
        title: "Dashboard",
        icon: "mdi-monitor-dashboard",
        to: "/clientes/dashboard",
      },
      { title: "Mi perfil", icon: "mdi-account", to: "/clientes/perfil" },
      {
        title: "Mis cobros",
        icon: "mdi-clipboard-check",
        to: "/clientes/cobros",
      },
      {
        title: "Productos",
        icon: "mdi-tag-multiple",
        to: "/clientes/productos",
      },
      {
        title: "Proveedores",
        icon: "mdi-truck-check",
        to: "/clientes/proveedores",
      },
    ],
  }),

  computed: {
    ...mapState(["barColor", "barImage", "user", "tipoUser"]),

    computedItems() {
      return this.items.map(this.mapItem);
    },
  },

  methods: {
    isCurrentRoute(path){
      return path == this.$route.path || path.name == this.$route.name
    },
    ...mapActions(["RegisterLogout"]),
    mapItem(item) {
      return {
        ...item,
        children: item.children ? item.children.map(this.mapItem) : undefined,
        title: this.$t(item.title),
      };
    },
    logout() {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton:
            "v-btn v-btn--contained v-size--default v-application primary bgsucces",
          cancelButton:
            "v-btn  theme--light v-size--default v-application primary bgcancel",
          title: "title-class",
        },
        buttonsStyling: false,
      });
      swalWithBootstrapButtons
        .fire({
          title: "¿Seguro que desea cerrar la sesión?",
          text: "",
          icon: "question",
          showCancelButton: true,
          confirmButtonText: "Sí, cerrar!",
          cancelButtonText: "No!",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.value) {
            if (this.RegisterLogout(this.$store.state)) {
              setTimeout(() => {
                EventBus.$emit("logout", "Haz cerrado la sesión");
                if (this.$store.state.tipoUser) {
                  if (this.$store.state.tipoUser == "cliente") {
                    this.$router.push("/clientes");
                  } else if (this.$store.state.tipoUser == "admin") {
                    this.$router.push("/admin");
                  } else if (this.$store.state.tipoUser == "proveedor") {
                    this.$router.push("/proveedor");
                  }
                }
              }, Configuration.redirect.timeout);
            }
          }
        });
    },
  },
};
</script>

<style>
.active{
  pointer-events: none !important;
  
}
.menuGiratorio {
  transform: rotate(180deg);
}
</style>
