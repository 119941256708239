// export const Dash = [
//   {
//     icon: "mdi-monitor-dashboard",
//     text: "Dashboard",
//     to: {
//       name: "dashboard"
//     }
//   }
// ]
export const MenusProveedor = [
    {
      icon: "mdi-align-vertical-bottom",
      text: "proveedor",
      to: "/proveedor/proveedores"

    },
]
export const PerfilProveedor = [
  {
    icon: "mdi-emoticon-poop",
    text: "Mis Productos",
    to: "/proveedor/editar-proveedor",
  },
]
